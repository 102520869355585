const routes = [
  {
    path: 'login',
    name: 'login',
    component: () => import('@/modules/auth/views/LoginView.vue'),
    meta: {
      hide: true,
      title: 'LOGIN',
    },
  },
  {
    path: 'register',
    name: 'register',
    component: () => import('@/modules/auth/views/RegisterView.vue'),
    meta: {
      hide: true,
      title: 'CREATE ACCOUNT',
    },
  },
  {
    path: 'forgot-password',
    name: 'forgot.password',
    component: () => import('@/modules/auth/views/PasswordRecoveryView.vue'),
    meta: {
      hide: true,
      title: 'FORGOT PASSWORD',
    },
  },
  // {
  //   path: 'verify-otp',
  //   name: 'verify.otp',
  //   component: () => import('@/modules/auth/views/VerifyOTPView.vue'),
  //   meta: {
  //     hide: true,
  //     title: 'QININA | Verify OTP',
  //   },
  // },
  {
    path: 'reset-password',
    name: 'reset.password',
    component: () => import('@/modules/auth/views/ResetPasswordView.vue'),
    meta: {
      hide: true,
      title: 'RESET PASSWORD',
    },
  },
];

export default routes;
