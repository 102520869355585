import { LoginData } from './types';
import {
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_LOGGED_USER,
  SET_TOKEN,
} from './constants';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { AuthState } from '../auth/types';
import { User } from '@/models/user';
import getLoggedUser from '@/api/auth/getLoggedUser';
import { AUTH_TOKEN } from '@/constants';
// import router from '@/router';

interface Context {
  commit: Commit;
  state: AuthState;
}

const authModule: Module<AuthState, State> = {
  state: {
    user: new User(),
    loggedIn: false,
    token: '',
  },
  mutations: {
    [LOGIN_USER](state: AuthState, loginData: LoginData) {
      state.user = loginData.user;
      state.loggedIn = true;
      state.token = loginData.token;
    },

    [LOGOUT_USER](state: AuthState) {
      state.user = new User();
      state.loggedIn = false;
      state.token = '';
      localStorage.removeItem(AUTH_TOKEN);
    },
    [FETCH_LOGGED_USER](state: AuthState, data: { user: User }) {
      state.user = data.user;
      state.loggedIn = true;
    },

    [SET_TOKEN](state: AuthState, token: string) {
      state.token = token;
    },
  },
  actions: {
    // async login({ commit }, { username, password }) {
    //   // eslint-disable-next-line no-useless-catch
    //   try {
    //     // const user = await login(username, password); // Use the login function from fakeApi
    //     // commit('setUser', user);
    //     // commit('setUser', user);
    //   } catch (error) {
    //     throw error;
    //   }
    // },
    [FETCH_LOGGED_USER]: async ({ commit, state }: Context) => {
      const token = localStorage.getItem(AUTH_TOKEN);

      if (token) {
        commit('SET_TOKEN', token);
      }
      // console.log(localStorage.getItem(AUTH_TOKEN));

      if (!state.token) {
        return;
      }

      const user = await getLoggedUser(token);
      // console.log('the user', user);

      return commit('FETCH_LOGGED_USER', user);
    },
  },
  getters: {
    currentUser: (state) => state.user,
    isLoggedIn: (state) => state.loggedIn,
  },
};

export default authModule;
